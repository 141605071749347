@import "../../../constants/vars";

.header.header.header.header.header {
  position: fixed;
  width: 100vw;
  margin-top: -9rem;
  z-index: 10;
  padding: 0 22px;
  background-color: $brownish-grey;

  .ic_arrowleft {
    height: 3.5rem;
    margin-right: 0px;
    margin-top: -3px;
  }
  &.is-blackboard {
    background-color: $white;

    .title,
    .back-button.back-button.back-button.back-button {
      color: $brownish-grey;
    }
  }
  &.is-login {
    background-color: transparent;
  }

  .title {
    @media (max-width: 500px) {
      font-size: 2.4rem;
    }
    text-align: center;
    display: inline-block;
    max-width: calc(100% - 60px);
    font-size: 3.2rem;
    font-weight: 300;
    letter-spacing: 1.5px;
    color: $white;
    margin: auto;
  }

  .location-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 29px;

    h1 {
      color: $white;
      font-size: 3.2rem;
      font-weight: 300;
    }
  }

  .header-inner-wrapper {
    display: flex;
    align-items: center;
    height: 9rem;

    .language-button {
      margin-left: auto;
      margin-right: 20px;
      height: 45px;
    }

    // override bootstrap styles
    .back-button.back-button.back-button.back-button {
      position: absolute;
      right: 16px; 
      text-decoration: none;
      font-weight: 300;
      @media (max-width: 500px) {
        height: calc(100% - 30px);
      }

      @at-root.is-login #{&} {
        color: $brownish-grey;

        img {
          filter: none;
        }
      }
    }
  }

  .logo-image {
    position: absolute;
    color: $white;
    height: calc(100% - 40px);

    &.logo-image-inverted {
      filter: invert(100%);
    }
  }

  .profile-button {
    position: absolute;
    right: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 6px;
    background-color: $white;
    
    @media (max-width: 575px) {
      right: 15px;
    }
    
    .no-profile-image {
      background-size: 150% 150%;
      &:hover {
        filter: invert(100%) brightness(200%) contrast(150%); // inverts color $brownish-grey (#8a7252) to white (#ffff)
      }
    }
    
    div {
      margin: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 45px;
      height: 45px;
      border-radius: 6px;
    }
    &.has-profile-image {
      padding: 0;

      img {
        background-size: cover;
      }
    }
  }
}