@import "../../constants/vars";

.gallery-cmp.gallery-cmp.gallery-cmp.gallery-cmp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -5px;

  .img-preview, .document-preview {
    margin: 5px;
    cursor: pointer;
  }

  .img-preview {
    position: relative;

    img {
      max-height: 106px;
      object-fit: contain;
    }
  }
  .document-preview {
    width: 174px;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $brownish-grey;

    span, a {
      font-size: 2.4rem;
      font-weight: 300;
      line-height: 1.13;
      color: $greyish-brown;
    }

    &.overlay {
      position: relative;
      width: 522px;
      height: 318px;
      top: calc(50% - 159px);
      left: calc(50% - 261px);

      span, a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }

  &.modal {

    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      max-width: none;
    }

    .modal-content {
      background-color: transparent;
      border: 0;
      width: 100%;
      height: 100%;
      padding: 3.2rem;

      .modal-header {
        border: 0;
        display: flex;
        justify-content: flex-end;

        img {
          cursor: pointer;
          width: 3.2rem;
          height: 33px;
        }
      }

      .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 0;

        .btn {
          padding: 0;
          min-width: 55px;

          img {
            margin: 0;
            padding: 0;
          }
        }

        .btn:first-child {
          margin-right: auto;
        }
        .btn:last-child {
          margin-left: auto;
        }

        .btn:last-child img {
          transform: scaleX(-1);
        }

        .preview, .img-preview {
          padding: 0 10px;
          width: 100%;
          height: 100%;

          img {
            max-height: 100%;
            max-width: 100%;
            margin: auto auto;
          }
        }

        .img-preview {
          display: flex;
        }
      }

      .btn {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
