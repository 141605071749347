@import '../../constants/vars';

.profilePage.profilePage.profilePage {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .col-md-12 {
      position: relative;
      display: flex;
      margin: 3.2rem 0;
    }

    .user-img, .user-img img {
      display: inline-block;
      position: relative;
      width: 150px;
      height: 150px;
      border-radius: 6px;
      background-size: 80% 80%;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 500px) {
        width: 100px;
        height: 100px;
      }
    }

    .user-img {
      background-color: $brownish-grey;

      &.has-profile-image img {
        background-size: cover;
      }
    }

    #upload-photo {
      display: none;
    }

    label {
      display: block;
    }

    .img-caption {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      position: absolute;
      width: 100%;
      bottom: 0;
      opacity: 0.9;
      border-radius: 0 0 6px 6px;
      background-color: $brown-grey;

      height: 3.2rem;
      font-family: $main-font;
      font-size: 2.4rem;
      color: $white;
      padding: 4px;

      span {
        cursor: pointer;
        margin-top: 4px;
        margin-bottom: 0;
      }
    }

    .btn-secondary {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
    }

    .user-name {
      display: inline-block;
      margin-top: auto;
      margin-left: 15px;
      font-size: 40px;
      line-height: 1em;
      color: $brownish-grey;

      @media (max-width: 500px) {
        font-size: 22px;
      }
    }

    .user-apt {
      height: 43px;
      font-size: 3.2rem;
      font-weight: 300;
      color: $greyish-brown;
    }

    .user-hr {
      border: solid 1px $very-light-pink;
      margin: 12px 0 0;
    }

    .user-email {
      height: 43px;
      font-size: 3.2rem;
      font-weight: 300;
      color: $greyish-brown;
    }

    .btn-primary {
      width: 100%;
      font-size: 3.2rem;
      font-weight: normal;
      letter-spacing: 1.5px;
      color: $white;
      margin-top: 64px;
    }

    .row:last-child {
      margin-top: -15px;
      .col-md-12 {
        margin: 0;
      }
      .password-changed {
        width: 100%;
        text-align: center;
        height: 44px;
        font-family: $main-font;
        font-size: 2.4rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: $irish-green;
      }
    }
  }
}
