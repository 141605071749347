@import '../../constants/vars';

.homepage.homepage.homepage.homepage {
  display: flex;
  padding-top: 55px;

  .container {
    padding: 0;
  }

  button {
    width: 100%;
    font-size: 3.2rem;
    font-weight: normal;
    letter-spacing: 1.5px;
    margin: 20px 0;
  }
  .col-md-4 {
    margin: 20px 0;
  }
  
  .feature-wrapper {  
    position: relative;
    height: 180px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $brownish-grey;
    border-radius: 8px;
    transition: box-shadow 0.3s;
    box-shadow: 1px 2px 6px 0 $shadow-color;
    cursor: pointer;
    
    .badge {
      position: absolute;
      right: 11px;
      top: 10px;
      background-color: $white;
      
      span {
        color: $brownish-grey;
        line-height: 10px;
      }
      
      &::before {
        content: attr(data-content);
        display: none;
      }
    }


    .feature-caption {
      line-height: normal;
    }

    img {
      width: 120px;
      padding: 2rem;  
      justify-self: center;
      color: $white;
    }

    &.feature-wrapper {
      width: 100%;
      text-align: center;
      color: $white;
      font-size: 22px;
      padding-bottom: 5px;
    }
  }
}
