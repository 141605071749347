@import '../../constants/vars';

.phoneNumbers.phoneNumbers.phoneNumbers.phoneNumbers {
  display: flex;

  .text {
    font-family: $main-font;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
    color: $greyish-brown;
    margin-top: 3.2rem;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    display: block;
  }

  ul {
    width: 100%;
    padding: 0;

    li {
      display: flex;
      list-style: none;
      height: 91px;
      border-radius: 10px;
      box-shadow: 1px 2px 6px 0 $shadow-color;
      border: solid 1px $very-light-pink;
      background-color: $white;
      margin: 2.4rem 0;
      cursor: pointer;

      transition: box-shadow 0.3s;

      @media (min-width: 501px) {
        &:hover {
          box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
        }
      }


      &.highlighted {
        border: solid 4px $greyish;

        img {
          margin-left: 29px;
        }
      }

      img {
        width: 45px;
        margin: auto 3.2rem;
        object-fit: contain
      }

      span {
        font-family: $main-font;
        font-size: 3.2rem;
        font-weight: 300;
        line-height: 1;
        color: $greyish-brown;
        margin: auto 0;

        &.long {
          font-size: 2.4rem;
        }

        .phone-number {
          white-space: nowrap;
        }
      }
    }
  }
}
