@import "../../constants/vars";

.services.services.services.services {
  display: flex;

  .container {
    padding: 0;
  }

  .text {
    font-family: $main-font;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
    margin-top: 3.2rem;
    text-align: center;
  }

  ul {
    width: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      display: inline-block;
      width: 22.2rem;
      height: 14.7rem;
      list-style: none;
      border-radius: 10px;
      box-shadow: 1px 2px 6px 0 $shadow-color;
      border: solid 1px $very-light-pink;
      background-color: $brownish-grey;
      margin: 6px;
      cursor: pointer;
      transition: box-shadow 0.3s;

      @media (min-width: 501px) {
        &:hover {
          box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
        }
      }

      div {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        padding: 0px 10px;

        img {
          height: 7.5rem;
          object-fit: contain;
          margin-top: 0.5rem;
        }

        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 2.2rem;
          color: $white;
          line-height: 1.1;
          height: 45%;
        }
      }

      &.highlighted {
        border: solid 4px $greyish;

        img {
          margin-left: 29px;
        }
      }
    }
  }
}

.provider-info.provider-info.provider-info.provider-info {
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    font-family: $main-font;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
    color: #2f2f2f;
    padding: 3.2rem 0 0;
    padding-bottom: 25px;
    border-bottom: solid 1px $very-light-pink;

    &:last-child {
      border-bottom: none;
    }

    .title {
      font-family: $main-font;
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .text,
    .contact,
    .hours {
      margin-bottom: 16px;
    }

    .phone,
    .email:not(:last-child) {
      margin-bottom: 0;
    }

    .phone-helpdesk {
      font-size: 150% !important;
    }

    .btn-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 20px;
      
      @media (max-width: 500px) {
        justify-content: center;
      }

      a {  
        &.phone,
        &.email,
        &.website {
          font-weight: normal;
          color: $brownish-grey;
          text-decoration: none;
        }
  
        button {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          width: 220px;
          height: 150px;
          font-size: 22px;
          text-align: center;
          background-color: $brownish-grey !important;
          padding-bottom: 10px;
  
          @media (max-width: 500px) {
            width: 85vw;
            margin: 5px;
          }

          img {
            height: 80px;
            margin: 0px;
          }
        }
      }
    }

    b {
      font-weight: bold;
    }
  }

  .col-md-12 {
    margin-top: 2rem;
  }

  .done-fetching {
    width: 100%;
    height: 307px;
    font-family: $main-font;
    font-size: 2.4rem;
    line-height: 1.33;
    text-align: center;
    color: $brownish-grey;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  button {
    width: 100%;
    height: 65px;
    font-size: 3.2rem;
    font-weight: normal;
    letter-spacing: 1.5px;
    margin-top: 20px;
  }
}

.contact-modal {
  .modal-dialog {
    max-width: 90%;

    @media (max-width: 575px) {
      max-width: initial;
    }

    .modal-header {
      align-items: flex-end;
      justify-content: flex-end;
      button {
        margin-left: 20px;
      }
    }
  }
}

.Toastify {
  font-size: 18px;

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    position: absolute;
    right: 8px;
    top: 0;
  }

  div {
    font-family: $main-font;
  }
}
