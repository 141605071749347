@import "../../constants/vars";

// override bootstrap styles
.language-button.language-button.language-button.language-button {
  border-radius: 6px;
  border: none;
  font-size: 2.4rem;
  font-weight: 300;
}
.language-modal.language-modal.language-modal {
  .modal-content {
    width: 437px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;

      li button {
        text-decoration: none !important;

        width: 129px;
        height: 45px;
        font-family: $main-font;
        font-size: 2.4rem;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $greyish-brown !important;

        &.active {
          font-weight: normal !important;
        }
        &:hover, &:active {
          box-shadow: none !important;
        }
      }
    }
  }
}
