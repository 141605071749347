@import "../../constants/vars";

.inputWrapper {
  display: inline-block;
  position: relative;
  padding: 0 0 1rem;

  .eye-image {
    position: absolute;
    top: 1.5rem;
    right: 3rem;
    cursor: pointer;
    margin: 0;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 6px;
    width: 6rem;
    height: 6rem;
  }

  .input{
    @media (max-width: 500px) {
      width: 100%;
    }
    height: 9rem;
    border-radius: 10px;
    border: solid 2px $brown-grey;
    font-family: $main-font;
    font-size: 3.2rem;
    font-weight: 300;
    padding: 2.4rem 1.6rem;
    color: $greyish-brown;

    &::placeholder {
      color: $very-light-pink;
    }
    &:focus::placeholder {
      color: transparent;
    }
    &:disabled {
      border-color: transparent;
    }
  }

  .inline-label, .input ~ p {
    font-family: $main-font;
    font-size: 2.4rem;
    font-weight: 300;
    color: $brownish-grey;
    left: 1.5rem;
    padding: 0 0.5rem;
    position: absolute;
    top: -1.8rem;
    background-color: $white;
    transition: 0.3s top ease;
    z-index: 0
  }

  .input ~ p {
    color: red;
    pointer-events: none;
  }

  .input {
    //transition: 0.3s all ease;
  }

  .input + .inline-label {
    opacity: 0;
    pointer-events: none;
  }

  .input:focus + .inline-label
  //.input.has-value + .inline-label
  {
    opacity: 1;
  }
  .input.has-value:not(.has-error), .input:not(.is-dirty) {
    margin-bottom: 4.7rem;
  }
  .input:focus ~ p {
    opacity: 0;
  }

  .error {
    @media (max-width: 500px) {
      width: 100%;
      text-align: center;
    }
    font-family: $main-font;
    font-size: 2.4rem;
    line-height: 1.33;
    color: $red;
    padding: 0.8rem 0;
    text-align: left;
  }
}
