@import '../../constants/vars';

.message-item.message-item.message-item.message-item {
  .user-info {
    display: flex;

    .user-img div {
      display: inline-block;
      background-size: cover;
      background-position: center;
      object-fit: contain;
      width: 81px;
      height: 81px;
      border-radius: 6px;
      margin-right: 16px;
      content: "";
    }

    .user-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .user-name {
        font-size: 3.2rem;
        color: $brownish-grey;
        line-height: 1.3;
      }

      .message-date {
        font-size: 2.4rem;
        color: $brown-grey;
      }
    }
  }

  .message-text {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.13;
    color: $greyish-brown;
    margin: 17px 0;
    text-align: left;
  }

  .message-social {
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
      justify-content: space-between;
    }
    padding: 0;

    .message-likes, .message-comments {
      display: flex;
      align-items: center;
      margin: 20px 16px 0;
      font-size: 2.4rem;
      font-weight: 300;
      line-height: 1.13;
      color: $white;
      background-color: $brownish-grey;
      padding-right: 20px;
      width: auto;
      box-shadow: none;

      @media (max-width: 500px) {
        margin: 20px 0 0;
      }

      &:hover:not(:disabled), &:active:not(:disabled) {
        background-color: darken($brownish-grey, 10%) !important;
        @media (min-width: 501px) {
          box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
        }
      }

      img {
        margin-right: 10px;
      }
    }
    .message-likes img {
      margin-top: -10px;
    }
  }

}
