@import "./vars";

// override bootstrap styles
.btn.btn.btn.btn {
  font-family: $main-font;
  font-size: 2.4rem;
  font-weight: normal;
  color: $white;
  height: 6.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.btn-primary {
    background-color: $brownish-grey;
    border-color: $brownish-grey;
    box-shadow: 1px 2px 6px 0 $shadow-color;
    border-radius: 10px;

    &:hover:not(:disabled), &:active:not(:disabled) {
      background-color: darken($greyish, 10%);
      @media (min-width: 501px) {
        box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
      }
    }
  }

  &.btn-secondary {
    box-shadow: 1px 2px 6px 0 $shadow-color;
    background-color: $very-light-pink;
    border: none;
    color: $white;

    &:hover:not(:disabled), &:active:not(:disabled) {
      background-color: darken($very-light-pink, 10%);
      border: none;
      @media (min-width: 501px) {
        box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
      }
    }
  }

  &.btn-emergency {
    background-color: #DC001D;
    border-color: #DC001D;
    box-shadow: 1px 2px 6px 0 $shadow-color;
    border-radius: 10px;

    &:hover:not(:disabled), &:active:not(:disabled) {
      background-color: darken(#DC001D, 10%);
      @media (min-width: 501px) {
        box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
      }
    }
  }

  img {
    margin-right: 10px;
    margin-top: -6px;
  }

  &:disabled {
    opacity: 0.45;
    box-shadow: none;
    font-weight: 300;
  }

  &:hover:not(:disabled), &:active {
    text-decoration: none;
    @media (min-width: 501px) {
      box-shadow: 1px 2px 6px 0 $shadow-color;
    }
  }
}
