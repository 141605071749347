@import "../../constants/vars";

.input-textarea {
  position: relative;

  textarea {
    width: 100%;
    height: 307px;
    max-height: 22rem;
    border-radius: 10px;
    box-shadow: 1px 2px 6px 0 $shadow-color;
    border: solid 1px $very-light-pink;
    background-color: $white;
    padding: 2.4rem 3.2rem;

    font-family: $main-font;
    font-size: 3.2rem;
    line-height: 1;
    font-weight: 300;

    &:focus {
      color: $greyish-brown;
    }
  }

  .placeholder {
    position: absolute;
    width: calc(100% - 6.4rem);
    top: 2.4rem;
    left: 3.2rem;
    pointer-events: none;

    font-family: $main-font;
    font-size: 3.2rem;
    line-height: 1;
    font-weight: 300;
    color: $greyish-brown;

    background-color: white !important;

    span span {
      color: $greyish;
    }
  }
}
