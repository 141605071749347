@import "../../constants/vars";

.loader {
  text-align: center;
  margin: 40px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full viewport height
  width: 100vw; // Full viewport width
  position: fixed; // Ensures it covers the entire screen
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); // Optional: to give a background overlay
  z-index: 9999; // Ensure it's above other content
}
