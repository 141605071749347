@import '../../constants/vars';

.blackboard-form.blackboard-form.blackboard-form.blackboard-form {
  display: flex;

  .providerForm {
    width: 100%;

    .btn-create {
      background-color: $brownish-grey;
    }
  }

  .desc {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
    color: $greyish-brown;
    margin: 3.2rem 0 2.4rem;
  }

  .placeholder {
    color: $very-light-pink;
    background-color: white !important;
  }

  textarea {
    height: 251px;
  }

  .submitting-done {
    align-items: center;
    height: 575px;
    font-size: 2.4rem;
    line-height: 1.33;
    text-align: center;
    color: $brownish-grey;
  }

  .attachments-container {
    height: 85px;

    &.has-files {
      height: auto;
    }
  }

  .attachments-list {
    display: flex;
    padding: 10px 15px;

    .col-md-12 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.4rem;
      padding: 0;
      box-shadow: 1px 2px 6px 0 $shadow-color;
      border-radius: 10px;

      > img {
        height: 4.5rem;
        padding: 0 2.5rem;
      }
    }

    button {
      width: auto;
      margin-left: auto;

      img {
        width: 3.2rem;
        height: 3.2rem;
        display: block;
        background-color: $greyish;
        mask-size: cover;
        mask-position: center;
      }
    }
  }

  .attachments-row {
    margin: 2.4rem 0;
    padding: 0 15px;
    @media (max-width: 500px) {
      padding: 0;
    }

    button img {
      height: 4.5rem;
    }

    .col-md-6:first-child {
      padding-left: 0;

      @media (max-width: 767px) {
        margin-bottom: 15px;
        padding: 0;
      }

      @media (max-width: 500px) {
        margin-bottom: 15px;
        padding: 0 15px;

        button img {
          margin-left: 10px;
        }
      }
    }
    .col-md-6:last-child {
      padding-right: 0;

      @media (max-width: 767px) {
        margin-bottom: 10px;
        padding: 0;
      }

      @media (max-width: 500px) {
        margin-bottom: 10px;
        padding: 0 15px;

        button img {
          margin-left: 10px;
        }
      }
    }

    button {
      background-color: $white;
      color: $greyish-brown;
      border: 0;

      &:hover:not(:disabled), &:active:not(:disabled) {
        background-color: darken($white, 10%);
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  button {
    width: 100%;
    font-size: 3.2rem;
    font-weight: normal;
    letter-spacing: 1.5px;
    padding: 0;

    label {
      width: 100%;
      height: 100%;
      margin: 0;
      cursor: pointer;
      padding: 0 15px;
    }
  }

  #upload-photo, #upload-doc {
    display: none;
  }
}
