@import '../../constants/vars';

.loginPage.loginPage.loginPage {
  .container {
    max-width: calc(478px + 30px);
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: 50px;
      height: 65px;
    }
    button {
      font-size: 3.2rem;
      font-weight: 300;
      letter-spacing: 1.5px;
      width: 100%;
      max-width: 448px;
    }
    a {
      display: inline-block;
      height: 42px;
      font-family: $main-font;
      font-size: 3.2rem;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $brownish-grey;
      border-bottom: solid 2px $brownish-grey;
      padding-bottom: 10px;
      margin-top: 80px;
      text-decoration: none;
    }
    .inputWrapper, .inputWrapper input {
      width: 100%;
      max-width: 448px;
    }

    input[name=password] {
      padding-right: 102px;
      
      @media (max-width: 500px) {
        padding-right: 68px;
      }
    }

    .apt-col .inputWrapper {
      margin-bottom: 0;
      .input {
        margin-bottom: 40px;
      }
    }
    .pwd-col .inputWrapper {
      margin-bottom: 0;
      .error {
        height: 78px;
      }
      .input {
        margin-bottom: 0px;
      }
      .input.has-value:not(.has-error), .input:not(.is-dirty) {
        margin-bottom: 40px;
      }
    }
  }
  .error {
    @media (max-width: 500px) {
      width: 100%;
    }
    font-family: $main-font;
    font-size: 2.4rem;
    line-height: 1.33;
    color: $red;
    padding: 0.8rem 0;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .loginPage.loginPage.loginPage {
    .container {
      button {
        max-width: 300px;
      }

      .inputWrapper, .inputWrapper input {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}
