@import '../../constants/vars';

.changePasswordPage.changePasswordPage.changePasswordPage {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 30px;

    .inputWrapper, input, button {
      width: 100%;
    }

    .inputWrapper {
      margin: 17px 0;
      padding: 0;
    }

    button {
      margin-top: 30px;
      font-size: 3.2rem;
      font-weight: normal;
      letter-spacing: 1.5px;
    }
  }
}
