@import "./constants/buttons";
@import "./constants/vars";

textarea, select, input, button { outline: none; }

.page-wrapper {
  font-family: $main-font;
  padding-top: 9rem;
}

.row.row {
  margin-right: 0;
  margin-left: 0;
}

.hidden {
  display: none !important;
}

.Toastify__progress-bar {
  height: 3px;
  background: $greyish;
}
.Toastify__toast--default {
  color: $red;
  border-radius: 10px;
}

html { font-size: 62.5%; }  /* =10px */

@media (max-width: 500px) {
  html { font-size: 41.6666%; }  /* =10px */
}
