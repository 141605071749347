@import '../../constants/vars';

.message-page.message-page.message-page.message-page {
  display: flex;

  .messageForm {
    width: 100%;
  }

  button {
    width: 100%;
    font-size: 3.2rem;
    font-weight: normal;
    margin: 2.4rem 0;

    img {
      margin: 0 20px;
    }
  }

  .user-info {
    display: flex;
    margin-top: 48px;

    .user-img div {
      display: inline-block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      object-fit: contain;
      width: 81px;
      height: 81px;
      border-radius: 6px;
      margin-right: 16px;
      content: "";
    }

    .user-img {
      &.has-profile-image img {
        background-size: cover;
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .user-name {
        font-size: 3.2rem;
        color: $brownish-grey;
        line-height: 1.3;
      }

      .message-date {
        font-size: 2.4rem;
        color: $brown-grey;
      }
    }
  }

  .comment-created {
    height: 242px;
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    text-align: center;
    color: $brownish-grey;
  }

  .message-text {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.13;
    color: $greyish-brown;
    margin: 17px 0;
    text-align: left;
  }

  .message-attachments {
    overflow: hidden;
  }

  .message-social {
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
      justify-content: space-between;
    }
    padding: 0;

    .btn {
      height: auto;
      width: 30rem !important;
    }

    .message-likes, .message-comments {
      display: flex;
      align-items: center;
      margin: 2rem 1.6rem 0;
      font-size: 2.4rem;
      font-weight: 300;
      line-height: 1.13;
      color: $white;
      background-color: $brownish-grey;
      padding-right: 20px;
      width: auto;
      box-shadow: none;

      &:hover:not(:disabled), &:active:not(:disabled) {
        background-color: darken($brownish-grey, 10%);

        @media (min-width: 501px) {
          box-shadow: 1px 2px 6px 0 rgba($shadow-color, 0.43);
        }
      }

      img {
        height: 4.5rem;
        margin-right: 10px;
        margin-left: 0px;
      }
    }
    .message-likes img {
      margin-top: -10px;
    }
  }

  textarea {
    height: 81px;
    margin: 25px 0 16px;
    font-size: 2.4rem;
  }

  .placeholder {
    font-size: 2.4rem;
    font-weight: 300;
    color: $very-light-pink;
    top: 50px;
    background-color: white !important;
  }

  ul {
    padding: 0;

    li {
      list-style: none;

      &:last-child .comment {
        border-bottom: none;
      }

      .comment {
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid $very-light-pink;
        margin: 20px 5px;
        padding-bottom: 20px;

        .comment-data {
          display: flex;
          flex-direction: column;
        }

        .user-img img {
          width: 67px;
          height: 67px;
          border-radius: 6px;
          margin-right: 16px;
          content: "";
          background-size: 80% 80%;
          background-position: center;
          background-repeat: no-repeat;
          object-fit: contain;
          display: inline-block;
          color: $white;
          background-color: $brownish-grey;
        }

        .user-img {
          &.has-profile-image img {
            background-size: cover;
          }
        }

        .user-details {
          display: flex;

          .user-name {
            font-size: 2.4rem;
            color: $brownish-grey;
            margin-right: 8px;

            @media (max-width: 500px) {
              margin-right: 20px;
            }
          }
          .message-date {
            font-size: 20px;
            color: $brown-grey;
            margin-top: 5px;

            @media (max-width: 500px) {
              font-size: 14px;
              margin-top: 2px;
            }
          }
        }
        .comment-text {
          font-size: 2.4rem;
          font-weight: 300;
          line-height: 1.13;
          color: $greyish-brown;
        }
      }
    }
  }
}
