@import '../../constants/vars';

.blackboard-page.blackboard-page.blackboard-page.blackboard-page {
  display: flex;
  background-color: $white;

  .col-md-12 {
    @media (max-width: 500px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  button {
    width: 100%;
    font-size: 3.2rem;
    font-weight: 300;
    margin: 2.4rem 0px;
    text-align: left;
    background-color: $white !important;
    color: $brownish-grey !important;

    img {
      width: 4.5rem;
      height: 4.5rem;
    }

    .ic-plus {
      margin-left: 10px;
    }
  }

  ul {
    width: 100%;
    padding: 0;
    text-align: center;

    li {
      display: inline-block;
      width: 100%;
      list-style: none;
      border-radius: 10px;
      box-shadow: 1px 2px 6px 0 $shadow-color;
      border: solid 1px $brownish-grey;
      background-color: $white;
      margin: 8px 0;
      padding: 2.4rem;

      .user-info {
        display: flex;

        .user-img img {
          object-fit: contain;
          max-width: 81px;
          max-height: 81px;
          border-radius: 6px;
          margin-right: 16px;
        }

        .user-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .user-name {
            font-size: 3.2rem;
            color: $brownish-grey;
            line-height: 1.3;
          }

          .message-date {
            font-size: 2.4rem;
            color: $brown-grey;
          }
        }
      }

      .message-text {
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 1.13;
        color: $greyish-brown;
        margin: 17px 0;
        text-align: left;
      }

      .message-attachments {
        overflow: hidden;
      }

      .message-social {
        display: flex;
        flex-direction: row;
        @media (max-width: 500px) {
          justify-content: space-between;
        }

        .btn {
          height: auto;
          width: 30rem;
        }

        .message-likes, .message-comments {
          display: flex;
          align-items: center;
          font-size: 2.4rem;
          font-weight: 300;
          background-color: $brownish-grey !important;
          color: $white !important;

          @media (max-width: 500px) {
            margin: 20px 0 0;
          }

          img {
            width: 4.5rem;
            height: 4.5rem;
            margin-top: 0px;
          }
        }
        .message-likes img {
          margin-top: -1rem;
        }
      }
    }
  }

  ul.pagination {
    li {
      border: none;
      margin: 0 10px;
      padding: 0;

      &.active a {
        background-color: darken($white, 10%);
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 2.4rem;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
