@import "../../constants/vars";

.reception-info.reception-info.reception-info.reception-info {
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    font-family: $main-font;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
    color: #2f2f2f;
    padding: 3.2rem 0 0;
    border-bottom: solid 1px $very-light-pink;
    margin-bottom: 80px;

    &:last-child {
      border-bottom: none;
    }

    .title {
      font-family: $main-font;
      font-size: 2.4rem;
      font-weight: bold;
      color: $greyish;
      margin-bottom: 16px;
    }

    .phone {
      margin-bottom: 0;
    }
    a {
      &.phone,
      &.email {
        font-weight: normal;
        color: $greyish;
        text-decoration: none;
      }
    }

    b {
      display: inline-block;
      margin-top: 2.4rem;
      font-weight: bold;
    }
  }

  button {
    width: 100%;
    height: 65px;
    font-size: 3.2rem;
    font-weight: normal;
    letter-spacing: 1.5px;
    margin-top: 20px;
  }
}
