@import '../../constants/vars';

.forgotPasswordPage.forgotPasswordPage.forgotPasswordPage {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (min-width: 501px) {
      margin-top: -75px;
    }

    .inputWrapper, input, button {
      width: 100%;
    }

    img:not(.logo-image) {
      position: absolute;
      top: 45px;
      right: 35px;
      cursor: pointer;
    }

    .logo-image {
      margin-bottom: 50px;
      height: 65px;
    }

    button {
      width: 100%;
      font-size: 3.2rem;
      font-weight: 300;
      letter-spacing: 1.5px;
      margin-top: 150px;
    }
    a {
      display: inline-block;
      width: 416px;
      height: 42px;
      font-family: $main-font;
      font-size: 3.2rem;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $brownish-grey;
      border-bottom: solid 2px $brownish-grey;
      padding-bottom: 10px;
      margin-top: 80px;
      text-decoration: none;
    }
    p {
      font-family: $main-font;
      font-size: 2.4rem;
      font-weight: 300;
      line-height: 1.33;
      text-align: center;
      color: $greyish-brown;
    }
  }
}
